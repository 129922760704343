
class HomeController {

    constructor ($rootScope) {
       this.rootScope = $rootScope;
    }


}
HomeController.$inject = ['$rootScope']
/* @ngInject */
export default HomeController